import { AxiosError } from 'axios'
import { message } from 'antd'
import { t } from 'i18next'
import EcService from '../ec.service'
import { AuthEcInfoType } from '../../type/AuthType'
import {
    BlogComment,
    CreateBlogCommentRequest,
    BlogDetailEc,
    BlogLike,
    BlogCommentSendingResponse,
    BlogActionResponse,
} from '../../type/BlogType'

class BlogEcService extends EcService {
    public detail(id: number) {
        return new Promise<BlogDetailEc>((resolve, reject) => {
            this.client
                .get(`blog/${id}`)
                .then((response) => {
                    resolve(response.data as BlogDetailEc)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public listComment(id: number) {
        return new Promise<BlogComment[]>((resolve, reject) => {
            this.client
                .get(`blog/${id}/comments`)
                .then((response) => {
                    resolve(response.data as BlogComment[])
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public createComment(id: number, data: CreateBlogCommentRequest) {
        return new Promise<BlogActionResponse>((resolve, reject) => {
            this.client
                .post(`blog/${id}/comments/create`, {
                    ...data,
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error: AxiosError) => {
                    // message.error(
                    //     error?.response?.data?.err_msg?.error ??
                    //         t('error.server_error')
                    // )
                    if (
                        !(
                            error?.response?.data?.err_msg?.error ===
                            BlogCommentSendingResponse.CREATE_COMMENT_FAILED_RESPONSE
                        )
                    ) {
                        message.error(t('error.server_error'))
                    }

                    reject(error)
                })
        })
    }

    public deleteComment(blogId: number, id: number, authId: string) {
        return new Promise<BlogActionResponse>((resolve, reject) => {
            this.client
                .post(`blog/${blogId}/comments/delete/${id}`, {
                    auth_id: authId,
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public blogLike(blogId: number, authId: string) {
        return new Promise<BlogLike>((resolve, reject) => {
            this.client
                .post(`blog/${blogId}/like`, {
                    authId,
                })
                .then((response) => {
                    resolve(response.data as BlogLike)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public blogUnlike(blogId: number, likeId: number, authId: string) {
        return new Promise((resolve, reject) => {
            this.client
                .post(`blog/${blogId}/unlike/${likeId}`, {
                    authId,
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }
}

export default new BlogEcService()
