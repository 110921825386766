import { SNS_KEYS, snsKeyType } from '../hook/creator/useCreatorSettingPage'
import { CreatorBackgroundType } from '../type/CreatorType'
import { BG_TYPE_COLOR, DEFAULT_BG_COLOR } from './constant'

export const getPreviewImageSrc = (
    url?: string,
    newUploadFileSrc?: string,
    currentFileSrc?: string
): string => {
    if (newUploadFileSrc) {
        return newUploadFileSrc
    }

    if (currentFileSrc) {
        return currentFileSrc
    }

    return url ? `${url}?no-cache` : ''
}

export const getBackgroundCss = (
    backgroundType: CreatorBackgroundType,
    backgroundColor?: string,
    imgSrc?: string
): string => {
    if (backgroundType === BG_TYPE_COLOR && backgroundColor)
        return backgroundColor ?? DEFAULT_BG_COLOR

    return `url(${imgSrc}) center top / 100% repeat`
}

export const getLinkRedirectSns = (
    path: string,
    snsType: snsKeyType
): string => {
    if (path.includes('https://')) return path

    if (snsType === SNS_KEYS.INSTAGRAM) {
        return `https://www.instagram.com/${path}`
    }

    if (snsType === SNS_KEYS.TWITTER) {
        return `https://x.com/${path}`
    }

    if (snsType === SNS_KEYS.TIKTOK) {
        if (path[0] !== '@') {
            return `https://tiktok.com/@${path}`
        }

        return `https://tiktok.com/${path}`
    }

    return path
}
