import React from 'react'
import { Link } from 'react-router-dom'
import header from '../../assets/images/portal/image_live_ticket/header_title.png'
import HeaderMenu from './HeaderMenu'
import HeaderRankingLink from './HeaderRankingLink'
import { useAppSelector } from '../../store/hooks/AppHooks'
import useGetWidthSize from '../../hook/useGetWidthSize'
import { checkArtistHiddenItems } from '../../utils/utils'
import ClickableWrapper from '../common/ClickableWrapper'

type Props = {
    logoStyle?: React.CSSProperties
    headerStyles?: React.CSSProperties
    headerWrapperStyles?: React.CSSProperties
    isClickable?: boolean
}
const Header = (props: Props): JSX.Element => {
    const {
        logoStyle,
        headerStyles,
        headerWrapperStyles,
        isClickable = true,
    } = props
    const artistInfo = useAppSelector((state) => state.artist.artistInfo)
    const isLoadingArtistInfo = useAppSelector(
        (state) => state.artist.isLoadingArtistInfo
    )
    const isHideLogo = checkArtistHiddenItems(artistInfo, isLoadingArtistInfo)
    const { width } = useGetWidthSize()

    return (
        <header className="portal-groups__header" style={headerStyles}>
            <div
                className="portal-groups__header__wrapper"
                style={headerWrapperStyles}
            >
                {isHideLogo ? null : (
                    <div className="portal-groups__header__fixed-content__container">
                        <div className="portal-groups__header__ranking">
                            <HeaderRankingLink />
                        </div>
                        <div className="portal-groups__logo">
                            <Link to="/portal">
                                <div className="portal-groups__logo__container">
                                    <div className="portal__logo__container">
                                        <span style={logoStyle}>
                                            CYBER STAR
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="portal-groups__header__side-menu-wrapper">
                            <ClickableWrapper isClickable={isClickable}>
                                {width < 768 && <HeaderMenu />}
                            </ClickableWrapper>
                        </div>
                    </div>
                )}
            </div>
        </header>
    )
}

Header.defaultProps = {
    logoStyle: null,
    headerStyles: null,
    headerWrapperStyles: null,
    isClickable: true,
}

export default Header
