import React, { Suspense, useEffect, useState } from 'react'
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom'
import { Empty } from 'antd'
import { AxiosError } from 'axios'
import _ from 'lodash'

import Loading from '../components/portal/Loading'
import { useAppDispatch, useAppSelector } from '../store/hooks/AppHooks'
import { GroupEc } from '../type/GroupType'
import { ArtistGroupActionType } from '../store/constant/group.const'
import UserService from '../services/user.service'

const GroupBlogComponent = React.lazy(
    () => import('../components/portal/blogs/Blogs')
)

const NewsList = React.lazy(
    () => import('../components/portal/groups/news/NewsList')
)

const LiveTicketComponent = React.lazy(
    () => import('../components/portal/liveTickets/LiveTickets')
)

const GroupArtistDetailComponent = React.lazy(
    () => import('../components/portal/groups/GroupArtistDetail')
)

const GroupNewsDetailComponent = React.lazy(
    () => import('../components/portal/groups/news/GroupNewsDetail')
)

const GroupBlogDetailComponent = React.lazy(
    () => import('../components/portal/blogs/BlogDetail')
)

const CreatorDetail = React.lazy(
    () => import('../components/portal/CreatorDetail')
)

function ArtistGroupRoutes(): JSX.Element {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { slug } = useParams<{ slug: string }>()
    const dispatch = useAppDispatch()
    const [me, setMe] = useState<any>()
    const fetchingErrors = useAppSelector((state) => state.artistGroup.errors)
    const { groupsEc } = useAppSelector((state) => state.artistGroup)
    const { ecId } = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )
    const { id } = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )

    const [groupDataFromEcTarget, setGroupDataFromEcTarget] = useState<GroupEc>(
        {} as GroupEc
    )

    const { pathname } = useLocation()

    useEffect(() => {
        if (fetchingErrors.length > 0) {
            fetchingErrors.forEach((error: AxiosError) => {
                if (error.response || error.request) {
                    history.push('/')
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchingErrors])

    useEffect(() => {
        dispatch({
            type: ArtistGroupActionType.fetchFromCms,
            payload: slug,
        })
    }, [dispatch, slug])

    useEffect(() => {
        if (id) {
            // Fetching the group-top's top carousel
            dispatch({
                type: ArtistGroupActionType.fetchGroupTopSlides,
                payload: { groupId: id },
            })
            // Fetching the group-top's middle carousel
            dispatch({
                type: ArtistGroupActionType.fetchGroupTopMiddleSlides,
                payload: { groupId: id },
            })
        }
    }, [dispatch, id])

    useEffect(() => {
        const groupDataFromEc = _.find(groupsEc, ({ id: ecIdStoredOnEc }) => {
            return ecIdStoredOnEc === ecId
        })
        if (!_.isUndefined(groupDataFromEc)) {
            setGroupDataFromEcTarget(groupDataFromEc)
        }
    }, [ecId, groupsEc, history, slug])

    useEffect(() => {
        if (pathname.includes('/FC') || pathname === `/groups/${slug}`) {
            UserService.getProfile().then((data) => {
                setMe(data)
            })

            if (pathname.includes('/FC')) {
                const currentUrl = new URL(
                    window.location.href.replace('/FC', '')
                )
                currentUrl.searchParams.set('FC', 'true')
                window.location = currentUrl
            }
        }
    }, [pathname])

    return (
        <>
            {id ? (
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route exact path={`${path}`} key="portal-artist">
                            <CreatorDetail isArtist={false} />
                        </Route>
                        <Route
                            exact
                            path={`${path}/artist/:artistId`}
                            key="group__artist-detail"
                        >
                            <GroupArtistDetailComponent />
                        </Route>
                        <Route
                            exact
                            path={`${path}/blog`}
                            key="portal-artist__blog"
                        >
                            <GroupBlogComponent isArtist={false} />
                        </Route>
                        <Route
                            exact
                            path={`${path}/blog/:blogId`}
                            key="group__blog-detail"
                        >
                            <GroupBlogDetailComponent isArtist={false} />
                        </Route>

                        <Route
                            exact
                            path={`${path}/personal-talk`}
                            key="group__personal-talk"
                        >
                            <LiveTicketComponent isArtist={false} />
                        </Route>
                        <Route exact path={`${path}/live`} key="group__live">
                            <LiveTicketComponent isArtist={false} />
                        </Route>
                        <Route
                            exact
                            path={`${path}/news`}
                            key="group_news_list"
                        >
                            <NewsList isArtist={false} />
                        </Route>
                        <Route
                            exact
                            path={`${path}/news/:newsId`}
                            key="group__news-detail"
                        >
                            <GroupNewsDetailComponent isArtist={false} />
                        </Route>
                    </Switch>
                </Suspense>
            ) : (
                <Empty
                    style={{ marginTop: '30px' }}
                    description="申し訳ございませんが、検索したいグループは見つかりませんでした。"
                />
            )}
        </>
    )
}

export default ArtistGroupRoutes
