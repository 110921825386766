import qs from 'qs'
import CmsService from '../cms.service'
import { BlogType } from '../../type/BlogType'
import { QueryParams } from '../../type/QueryParams'

class BlogService extends CmsService {
    public get(params: QueryParams = {}, artistId: string | number) {
        return new Promise<Array<BlogType>>((resolve, reject) => {
            const currentTime = new Date().toISOString()
            const defaultParams = {
                _where: {
                    _or: [
                        {
                            _and: { publish_at_lte: currentTime },
                        },
                        { publish_at_null: true },
                    ],
                    status: 'public',
                },
            }
            this.client
                .get(
                    `blogs?${qs.stringify({
                        ...defaultParams,
                        ...params,
                        artist_id: artistId,
                    })}`
                )
                .then((response) => {
                    const blogs = response.data.map((blog: BlogType) => {
                        return {
                            ...blog,
                            url: `/blogs/${blog.id}`,
                        }
                    })
                    resolve(blogs)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getById(id: string, params: QueryParams = {}) {
        return new Promise<BlogType>((resolve, reject) => {
            this.client
                .get(`blogs/${id}`, {
                    params,
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public count(params: QueryParams = {}) {
        return new Promise<number>((resolve, reject) => {
            this.client
                .get('blogs/count', {
                    params,
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new BlogService()
