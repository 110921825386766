import React from 'react'
import { RouteChildrenProps, useLocation } from 'react-router-dom'
import Header from '../components/portal/Header'
import HeaderMainMenu from '../components/portal/HeaderMainMenu'
import PortalRoutes from '../routes/PortalRoutes'
import withMasterLayout from '../components/hoc/WithMasterLayout'
import { isCreatorPage as checkIsCreatorPage } from '../utils/utils'
import { useAppSelector } from '../store/hooks/AppHooks'
import useCreatorSettingColor from '../hook/creator/useCreatorSettingColor'
import CreatorHeader from '../components/common/Creator/CreatorHeader'
import useCreatorElementStatuses, {
    CREATOR_STATUS_KEYS,
} from '../hook/creator/useCreatorElementStatuses'

export const PortalContext = React.createContext({})

function Layout({ match }: RouteChildrenProps): JSX.Element {
    const location = useLocation()
    const isCreatorPage = checkIsCreatorPage(location)
    const artistInfo = useAppSelector((state) => state.artist.artistInfo)
    const { getStatusElement } = useCreatorElementStatuses(artistInfo)
    const { settingColor } = useCreatorSettingColor(artistInfo)

    return (
        <PortalContext.Provider value={{}}>
            <section className="portal portal__wrapper">
                <div className="portal__container">
                    {isCreatorPage && artistInfo ? (
                        <CreatorHeader
                            position="fixed"
                            settingColor={settingColor}
                            isShow={getStatusElement(
                                CREATOR_STATUS_KEYS.IS_SHOW_HEADER_KEY
                            )}
                        />
                    ) : (
                        <>
                            <Header />
                            <HeaderMainMenu />
                        </>
                    )}
                    <PortalRoutes
                        prefix={match ? match.path : ''}
                        isCreatorPage={isCreatorPage}
                        isArtist
                    />
                </div>
            </section>
        </PortalContext.Provider>
    )
}

const PortalLayout = withMasterLayout<RouteChildrenProps>(Layout)
export default PortalLayout
