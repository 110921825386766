import { Action } from 'redux'
import { UserType } from '../../type/UserType'
import { PointType } from '../../type/PointType'
import { AuthEcInfoType } from '../../type/AuthType'

export type UserStateType = {
    isLoading: boolean
    me: UserType
    isAuth: boolean
    resultAfterTryLogout: resultStateType
    point: PointType
    authEcInfo: AuthEcInfoType | null
}

type resultStateType = {
    feedback: string
    message: string
}

export interface UserActions<T = any> extends Action {
    payload?: T
}

export const UserActionTypes = {
    getProfile: 'USER_GET_PROFILE',
    getAuthEcInfo: 'USER_GET_AUTH_EC_INFO',
    setAuth: 'SET_AUTH',
    setAuthEcInfo: 'USER_SET_AUTH_EC_INFO',
    setLoading: 'USER_SET_LOADING',
    setProfile: 'USER_SET_PROFILE',
    requestLogout: 'USER_REQUEST_LOGOUT',
    setResultAfterTryLogout: 'USER_SET_RESULT_AFTER_TRY_LOGOUT',
    fetchPoints: 'USER_FETCH_POINTS',
    setPoints: 'USER_SET_POINTS',
}
