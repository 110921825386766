import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
    label: string
    name?: string
    value?: string
    className?: string
    placeholder?: string
    inputRef?: React.RefObject<HTMLInputElement>
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    maxLength?: number
    defaultValue?: string
}

const InputText = (props: Props): JSX.Element => {
    const {
        label,
        name,
        className,
        placeholder,
        defaultValue,
        onChange,
        inputRef,
        maxLength,
    } = props
    const { control } = useFormContext() ?? {}

    return (
        <div className={className}>
            <p>{label}</p>
            {control && name ? (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <input
                            className="ant-input"
                            {...field}
                            ref={inputRef}
                        />
                    )}
                />
            ) : (
                <input
                    className={`ant-input ${className}`}
                    name={name}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    ref={inputRef}
                    maxLength={maxLength}
                />
            )}
        </div>
    )
}

InputText.defaultProps = {
    value: '',
    className: '',
    placeholder: '',
    name: null,
    inputRef: null,
    maxLength: undefined,
    defaultValue: undefined,
    onChange: undefined,
}

export default InputText
