import React, {
    ChangeEventHandler,
    FormEventHandler,
    MouseEventHandler,
    useState,
} from 'react'
import { Button, Drawer, Dropdown, Input, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { SearchOutlined, StarFilled } from '@ant-design/icons'
import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/AppHooks'
import HeaderMenuOverlay from '../HeaderMenuOverlay'
import { ArtistGroupActionType } from '../../../store/constant/group.const'
import header from '../../../assets/images/portal/image_live_ticket/header_title.png'
import useGetWidthSize from '../../../hook/useGetWidthSize'
import NftService from '../../../services/nft.service'

function HeaderRankingLink(): JSX.Element {
    return (
        <div className="portal-groups__header__ranking__wrapper">
            <Link to="#">
                <div className="portal-groups__header__ranking__image-container">
                    {/* <img */}
                    {/* src={imageCrown} */}
                    {/* alt="RANKING" */}
                    {/* className="portal-groups__header__ranking__image-image" */}
                    {/* /> */}
                </div>
            </Link>
        </div>
    )
}

const HeaderMenu = (): JSX.Element => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)

    const handleVisibleChange: () => MouseEventHandler | void = () => {
        setIsDropdownVisible(!isDropdownVisible)
    }
    const onClose = () => setIsDropdownVisible(false)

    return (
        // <Dropdown
        //     overlay={<HeaderMenuOverlay handleVisible={handleVisibleChange} />}
        //     placement="bottomRight"
        //     trigger={['click']}
        //     className="portal-groups__header__side-menu-menu__dropdown"
        //     visible={isDropdownVisible}
        //     onVisibleChange={(isVisible) => setIsDropdownVisible(isVisible)}
        // >
        //     <a
        //         className="portal-groups__header__side-menu-menu__link"
        //         onClick={(e) => {
        //             e.preventDefault()
        //         }}
        //         href="#"
        //     >
        //         <button
        //             className="portal-groups__header__side-menu-menu__button"
        //             type="button"
        //             onClick={handleVisibleChange}
        //         >
        //             <FontAwesomeIcon
        //                 icon={['fas', 'bars']}
        //                 className="portal-groups__header__side-menu-menu__button__icon"
        //             />
        //         </button>
        //     </a>
        // </Dropdown>
        <div>
            <button
                className="portal-groups__header__side-menu-menu__button"
                type="button"
                onClick={handleVisibleChange}
            >
                <FontAwesomeIcon
                    icon={['fas', 'bars']}
                    className="portal-groups__header__side-menu-menu__button__icon"
                />
            </button>
            <Drawer
                title="Menu"
                placement="right"
                onClose={onClose}
                visible={isDropdownVisible}
            >
                <HeaderMenuOverlay handleVisible={handleVisibleChange} />
            </Drawer>
        </div>
    )
}

const HeaderNavigation = (): JSX.Element => {
    const [searchedValue, setSearchedValue] = useState('')
    const dispatch = useAppDispatch()

    const handleSearchedValueChange: ChangeEventHandler<HTMLInputElement> = (
        e
    ) => {
        setSearchedValue(e.target.value)
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        dispatch({
            type: ArtistGroupActionType.setStringSearched,
            payload: searchedValue,
        })
    }

    const handleClickSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        dispatch({
            type: ArtistGroupActionType.setStringSearched,
            payload: searchedValue,
        })
    }

    return (
        <div className="portal-groups__header__navigation-wrapper">
            <div className="portal-groups__header__navigation-search">
                <form onSubmit={handleSubmit}>
                    <Input
                        size="large"
                        suffix={
                            <button type="button" onClick={handleClickSubmit}>
                                <SearchOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            </button>
                        }
                        className="portal-groups__input-search"
                        onChange={handleSearchedValueChange}
                    />
                </form>
            </div>
        </div>
    )
}

function GroupHeader(): JSX.Element {
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const userProfile = useAppSelector((state) => state.user.me)

    const [isDisplayInput, setIsDisplayInput] = useState(false)
    const { width } = useGetWidthSize()
    const handlePopupInput: MouseEventHandler<HTMLElement> = (e) => {
        setIsDisplayInput(!isDisplayInput)
    }

    const handleRedirectUrl = (path = 'my-auctions') => {
        if (isAuth) {
            NftService.getRedirectUrl(userProfile.pialaAuthToken, path).then(
                (url) => {
                    window.location.href = url
                }
            )
        } else {
            window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage/login`
        }
    }

    return (
        <header className="portal-groups__header">
            <div className="portal-groups__header__wrapper">
                <div className="portal-groups__header__fixed-content__container">
                    <div className="portal-groups__header__ranking">
                        <HeaderRankingLink />
                    </div>
                    <div className="portal-groups__logo">
                        <Link to="/portal">
                            <div className="portal-groups__logo__container">
                                <div className="portal__logo__container">
                                    <img src={header} alt="headerTitle" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="portal-groups__header__side-menu-wrapper">
                        {width < 768 && <HeaderMenu />}
                    </div>
                </div>
            </div>
            <div className="portal-groups__header__navigation">
                <div className="portal__header__menu__pc">
                    <ul className="portal__header__menu__pc--wrapper">
                        <li>
                            <Link to="/portal">TOP</Link>
                        </li>
                        <li>
                            <Link to="/portal#nft-mall">
                                {t('label.common.auction')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/portal#home-special">Special</Link>
                        </li>
                        <li>
                            <Link to="/portal#creator-list">
                                クリエイター一覧
                            </Link>
                        </li>
                        <li>
                            <a
                                href={`${process.env.REACT_APP_EC_API_URL}${process.env.REACT_APP_POINT_PRODUCTS}`}
                            >
                                ポイント購入
                            </a>
                        </li>
                        <li>
                            <Link to="/portal/favorites">お気に入り</Link>
                        </li>
                        <li>
                            <Link to="/portal/guide/user">使い方ガイド</Link>
                        </li>
                        <li>
                            <Link to="/portal/faq">FAQ</Link>
                        </li>
                        <li>
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => handleRedirectUrl()}
                                onClick={() => handleRedirectUrl()}
                            >
                                マイページ
                            </div>
                        </li>
                        <li>
                            <Link
                                to={`${process.env.REACT_APP_EC_API_URL}/entry/artist`}
                            >
                                クリエイター申請
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default GroupHeader
