import * as _ from 'lodash'
import qs from 'qs'
import { AxiosError } from 'axios'
import CmsService from '../cms.service'
import { ArtistInfoType } from '../../type/ArtistInfoType'
import { formatKeys } from '../../utils/filters'
import { QueryParams } from '../../type/QueryParams'
import { ArtistNewsType } from '../../type/ArtistType'

class ArtistCmsService extends CmsService {
    public getArtist(id: string) {
        return new Promise<ArtistInfoType>((resolve, reject) => {
            this.client
                .get(`piala/artists/${id}`)
                .then((response) => {
                    resolve({
                        ...response.data.data,
                        profile: response.data.data.introduction,
                    })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getArtists(ids: Array<string>, query: QueryParams = {}) {
        return new Promise<Array<ArtistInfoType>>((resolve, reject) => {
            const queryParams = { _sort: 'id:DESC', ...query }
            this.client
                .post(
                    'piala/artists/list',
                    {
                        ids,
                    },
                    {
                        params: queryParams,
                    }
                )
                .then((response) => {
                    const artists = response.data.data.map((artist: any) => {
                        return formatKeys(artist)
                    })
                    resolve(artists as ArtistInfoType[])
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    // public getListArtists(query: QueryParams = {}) {
    //     return new Promise<Array<ArtistInfoType>>((resolve, reject) => {
    //         this.client
    //             .post('piala/artists/list', {
    //                 params: query,
    //             })
    //             .then((response) => {
    //                 const artists = response.data.data.map((artist: any) => {
    //                     return formatKeys(artist)
    //                 })
    //                 resolve(artists as ArtistInfoType[])
    //             })
    //             .catch((error) => {
    //                 reject(error)
    //             })
    //     })
    // }

    public getNewsArtist(id: number | string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get(
                    `news-articles?artist_id=${id}&_limit=3&_start=0&_sort=created_at:DESC`
                )
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getArtistNewsList(id: number | string, params: QueryParams = {}) {
        return new Promise<Array<ArtistNewsType>>((resolve, reject) => {
            const currentTime = new Date().toISOString()
            const defaultParams = {
                _where: {
                    _or: [
                        {
                            _and: { end_at_gte: currentTime },
                        },
                        { end_at_null: true },
                    ],
                },
            }
            this.client
                .get(
                    `news-articles?${qs.stringify({
                        ...defaultParams,
                        ...params,
                        artist_id: id,
                        _limit: 3,
                        _start: 0,
                        _sort: 'id:DESC',
                    })}`
                )
                .then((response) => {
                    const artistNews = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(artistNews as Array<ArtistNewsType>)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getAllNews(id: number) {
        return new Promise<ArtistNewsType[]>((resolve, reject) => {
            this.client
                .get('artist-news', {
                    params: {
                        'artist.id': id,
                    },
                })
                .then((response) => {
                    const news = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(news as ArtistNewsType[])
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getListNews(id: number, limit: number, start: number) {
        return new Promise<Array<ArtistNewsType>>((resolve, reject) => {
            this.client
                .get('artist-news', {
                    params: {
                        _limit: limit,
                        'artist.id': id,
                        _start: start,
                        _sort: 'id:desc',
                    },
                })
                .then((response) => {
                    const artistNews = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(artistNews as Array<ArtistNewsType>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getCountNews(id: number) {
        return new Promise<number>((resolve, reject) => {
            this.client
                .get('artist-news/count', {
                    params: {
                        'artist.id': id,
                    },
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getNews(newsId: string) {
        return new Promise<ArtistNewsType>((resolve, reject) => {
            this.client
                .get(`artist-news/${newsId}`)
                .then((response) => {
                    const news: any = formatKeys(response.data)
                    resolve(news as ArtistNewsType)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getAllNewsArtist(id: number | string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get(`news-articles?artist_id=${id}&_sort=created_at:DESC`)
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getListNewsArtist(
        id: number | string,
        limit: number,
        start: number
    ) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get(
                    `news-articles?artist_id=${id}&_limit=${limit}&_start=${start}&_sort=created_at:DESC`
                )
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getArtistBySlug(slug: string) {
        return new Promise<ArtistInfoType>((resolve, reject) => {
            this.client
                .get(`piala/artists/${slug}`)
                .then((response) => {
                    const artist = formatKeys(response.data.data)
                    resolve(artist as ArtistInfoType)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new ArtistCmsService()
