import * as _ from 'lodash'
import { RGBColor } from 'react-color'
import { matchPath } from 'react-router-dom'
import { Location } from 'history'
import { RefObject } from 'react'
import { Crop } from 'react-image-crop'
import moment from 'moment'
import { t } from 'i18next'
import { FrontTheme } from '../type/FrontTheme'
import { hexToRgb, typedKeys } from './filters'
import { ArtistInfoType } from '../type/ArtistInfoType'
import routes from '../routes'
import { MediaType } from '../type/MediaType'
import { CreatorPreviewImage } from '../type/CreatorType'
import { BlogComment, BlogCommentFlag, BlogOwner } from '../type/BlogType'

export default function settingStyle(
    rootStyle: CSSStyleDeclaration,
    theme: FrontTheme
): void {
    typedKeys(theme).forEach((key) => {
        const replace = key.replace('Color', '')
        const prop = `--color-${_.kebabCase(replace)}`
        rootStyle.setProperty(prop, theme[key])

        if (theme[key]) {
            const propRgb = `--color-${_.kebabCase(replace)}-rgb`
            rootStyle.setProperty(propRgb, hexToRgb(theme[key]))
        }
    })
}

export function pluralize(word: string, amount?: number): string {
    if (amount !== undefined && amount === 1) {
        return word
    }
    const plural: { [key: string]: string } = {
        '(quiz)$': '$1zes',
        '^(ox)$': '$1en',
        '([m|l])ouse$': '$1ice',
        '(matr|vert|ind)ix|ex$': '$1ices',
        '(x|ch|ss|sh)$': '$1es',
        '([^aeiouy]|qu)y$': '$1ies',
        '(hive)$': '$1s',
        '(?:([^f])fe|([lr])f)$': '$1$2ves',
        '(shea|lea|loa|thie)f$': '$1ves',
        sis$: 'ses',
        '([ti])um$': '$1a',
        '(tomat|potat|ech|her|vet)o$': '$1oes',
        '(bu)s$': '$1ses',
        '(alias)$': '$1es',
        '(octop)us$': '$1i',
        '(ax|test)is$': '$1es',
        '(us)$': '$1es',
        '([^s]+)$': '$1s',
    }
    const irregular: { [key: string]: string } = {
        move: 'moves',
        foot: 'feet',
        goose: 'geese',
        sex: 'sexes',
        child: 'children',
        man: 'men',
        tooth: 'teeth',
        person: 'people',
    }
    const uncountable: string[] = [
        'sheep',
        'fish',
        'deer',
        'moose',
        'series',
        'species',
        'money',
        'rice',
        'information',
        'equipment',
        'bison',
        'cod',
        'offspring',
        'pike',
        'salmon',
        'shrimp',
        'swine',
        'trout',
        'aircraft',
        'hovercraft',
        'spacecraft',
        'sugar',
        'tuna',
        'you',
        'wood',
    ]
    // save some time in the case that singular and plural are the same
    if (uncountable.indexOf(word.toLowerCase()) >= 0) {
        return word
    }
    // check for irregular forms
    for (const w in irregular) {
        if (irregular[w]) {
            const pattern = new RegExp(`${w}$`, 'i')
            const replace = irregular[w]
            if (pattern.test(word)) {
                return word.replace(pattern, replace)
            }
        }
    }
    // check for matches using regular expressions
    for (const reg in plural) {
        if (plural[reg]) {
            const pattern = new RegExp(reg, 'i')
            if (pattern.test(word)) {
                return word.replace(pattern, plural[reg])
            }
        }
    }
    return word
}

export function redirectLogin(url: string | null): void {
    window.location.href = `${
        process.env.REACT_APP_EC_API_URL
    }/mypage/login?redirect=${url || window.location.href}`
}

export function checkArtistHiddenItems(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_ITEMS?.split(',')?.find(
            (artistId) => Number(artistId) === artistInfo?.artistId
        )
    )
}

export function checkArtistHiddenShopItems(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_SHOP_ITEMS?.split(',')?.find(
            (artistId) => Number(artistId) === artistInfo?.artistId
        )
    )
}

export function checkArtistHiddenNftLiveItems(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_NFT_LIVE_ITEMS?.split(
            ','
        )?.find((artistId) => Number(artistId) === artistInfo?.artistId)
    )
}

export function checkArtistHiddenNowOnAir(
    artistInfo: ArtistInfoType | null,
    isLoadingArtistInfo = false
): boolean {
    return !!(
        isLoadingArtistInfo ||
        process.env.REACT_APP_ARTIST_IDS_HIDDEN_NOW_ON_AIR?.split(',')?.find(
            (artistId) => Number(artistId) === artistInfo?.artistId
        )
    )
}

export const switchPositionByKey = <T>(
    currentArray: T[],
    key: keyof T,
    value1: string | number,
    value2: string | number
): T[] => {
    const array = [...currentArray]
    const index1 = array.findIndex((obj) => obj[key] === value1)
    const index2 = array.findIndex((obj) => obj[key] === value2)

    if (index1 === -1 || index2 === -1) {
        return currentArray
    }
    ;[array[index1], array[index2]] = [array[index2], array[index1]]

    return array
}

export const convertHexToRgba = (hex: string): RGBColor => {
    const newHex = hex.replace(/^#/, '')
    let r = 0
    let g = 0
    let b = 0
    let a = 1

    if (newHex.length === 6) {
        r = parseInt(newHex.substring(0, 2), 16)
        g = parseInt(newHex.substring(2, 4), 16)
        b = parseInt(newHex.substring(4, 6), 16)
        a = 1
    } else if (newHex.length === 8) {
        r = parseInt(newHex.substring(0, 2), 16)
        g = parseInt(newHex.substring(2, 4), 16)
        b = parseInt(newHex.substring(4, 6), 16)
        a = parseInt(newHex.substring(6, 8), 16) / 255
    }

    return { r, g, b, a }
}

export const converRgbaToHex = (rgba: RGBColor): string => {
    const [r, g, b, a] = Object.values(rgba)
    const hexR = r.toString(16).padStart(2, '0')
    const hexG = g.toString(16).padStart(2, '0')
    const hexB = b.toString(16).padStart(2, '0')
    const hexA = Math.round(a * 255)
        .toString(16)
        .padStart(2, '0')

    return `#${hexR}${hexG}${hexB}${hexA}`
}

export const generateRandomUniqueString = (length: number): string => {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    const charactersLength = characters.length

    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }

    return result
}

export const isCreatorPage = (location: Location<unknown>): boolean =>
    !!routes.find(
        (route) =>
            (route.name === 'portal-artist' ||
                route.name === 'portal-artist-group') &&
            matchPath(location.pathname, `${route.prefix}${route.path}`)
    )

export const convertCanvasToFile = (
    canvasRef: RefObject<HTMLCanvasElement>,
    fileName: string
): Promise<File | null> => {
    return new Promise<File>((resolve, reject) => {
        canvasRef.current &&
            canvasRef.current.toBlob((blob) => {
                if (blob) {
                    const file = new File(
                        [blob],
                        changeExtFileToJpeg(fileName),
                        {
                            type: 'image/jpeg',
                        }
                    )
                    resolve(file)
                } else {
                    reject(new Error('Canvas is empty or an error occurred.'))
                }
            }, 'image/jpeg')
    })
}

export const changeExtFileToJpeg = (fileName: string): string => {
    const name = fileName.split('.').slice(0, -1).join('.')

    return `${name}.jpeg`
}

export const handleSetPreviewImage = async (
    previewCanvasRef: React.RefObject<HTMLCanvasElement>,
    currentImage: MediaType | undefined = undefined,
    completedCrop: Crop | undefined = undefined,
    uploadFile: File | undefined = undefined,
    previewSrc: CreatorPreviewImage | undefined = undefined,
    newUploadFileSrc: string | undefined = undefined
): Promise<CreatorPreviewImage | null> => {
    if (!completedCrop) {
        return null
    }

    const fileName = uploadFile ? uploadFile.name : currentImage?.name

    if (previewCanvasRef && fileName) {
        const file = await convertCanvasToFile(previewCanvasRef, fileName)

        if (!file) {
            return null
        }

        return {
            previewCanvasRef,
            completedCrop,
            file,
            fileSrc: newUploadFileSrc ?? previewSrc?.fileSrc,
        }
    }

    return null
}

export const isValidUrl = (input: string): boolean => {
    try {
        const urlObj = new URL(input)
        return urlObj.protocol === 'http:' || urlObj.protocol === 'https:'
    } catch (_e) {
        return false
    }
}

export const timeComment = (
    createdAt: string,
    isNewComment?: boolean
): string => {
    const now = moment()
    const commentTime = moment(createdAt)
    const diffInSeconds = now.diff(commentTime, 'seconds')
    const diffInMinutes = now.diff(commentTime, 'minutes')
    const diffInHours = now.diff(commentTime, 'hours')
    const diffInDays = now.diff(commentTime, 'days')
    const diffInMonths = now.diff(commentTime, 'months')
    const diffInYears = now.diff(commentTime, 'years')

    if (isNewComment && diffInSeconds < 10) {
        return t('label.common.now')
    }

    if (diffInSeconds < 60) {
        return t('label.common.second', {
            second: diffInSeconds,
        })
    }
    if (diffInMinutes < 60) {
        return t('label.common.minute', {
            minute: diffInMinutes,
        })
    }
    if (diffInHours < 24) {
        return t('label.common.hour', {
            hour: diffInHours,
        })
    }
    if (diffInDays < 30) {
        return t('label.common.day', {
            day: diffInDays,
        })
    }
    if (diffInMonths < 12) {
        return t('label.common.month', {
            month: diffInMonths,
        })
    }

    return t('label.common.year', {
        year: diffInYears,
    })
}

export const handleSortListComment = (
    comment: BlogComment,
    currentList: BlogComment[],
    ownerId: number,
    flag: BlogCommentFlag
): BlogComment[] => {
    const newCommentList = [...currentList]
    const isBlogOwner =
        ownerId === comment.customer_id
            ? BlogOwner.IS_OWER
            : BlogOwner.IS_NOT_OWNER
    const newComment: BlogComment = {
        ...comment,
        is_owner: isBlogOwner,
        is_new_comment: true,
    }
    const parentCommentIdx = currentList.findIndex(
        (commentItem) => comment.parent_id === commentItem.id
    )

    // Handle when submit new comment
    if (flag === BlogCommentFlag.CREATED) {
        // if list comments are empty
        if (!currentList.length) {
            newCommentList.push(newComment)

            return newCommentList
        }

        // if list comments has data
        if (currentList.length) {
            /**
             * if comment is replied to another comment
             * => Push new comment on top of list
             * */
            if (parentCommentIdx !== -1) {
                const parrentComment = { ...currentList[parentCommentIdx] }
                const newChildrenComment = parrentComment.comments
                    ? [...parrentComment.comments]
                    : []
                newChildrenComment.unshift(newComment)
                newCommentList[parentCommentIdx].comments = newChildrenComment

                return newCommentList
            }

            // if comment is not replied to another comment

            /**
             * if user is blog owner
             * => Push new comment on top of list
             * */
            if (isBlogOwner) {
                newCommentList.unshift(newComment)

                return newCommentList
            }

            /**
             * if user is not blog owner
             * => Push new comment right under owener comment
             * */
            const lastIndex = newCommentList.findLastIndex(
                (commentItem) => commentItem.is_owner === BlogOwner.IS_OWER
            )

            newCommentList.splice(lastIndex + 1, 0, newComment)

            return newCommentList
        }
    }

    if (flag === BlogCommentFlag.DELETED) {
        if (parentCommentIdx !== -1) {
            const parrentComment = { ...currentList[parentCommentIdx] }
            const newChildrenComment = parrentComment.comments
                ? [...parrentComment.comments]
                : []
            newCommentList[parentCommentIdx].comments =
                newChildrenComment.filter(
                    (commentItem) => commentItem.id !== newComment.id
                )

            return newCommentList
        }

        return newCommentList.filter(
            (commentItem) => commentItem.id !== newComment.id
        )
    }

    return newCommentList
}

export const cutString = (text: string, number = 40, char = '...'): string => {
    if (text.length > number) {
        return text.slice(0, number) + char
    }

    return text
}
